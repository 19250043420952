import React from "react"
import {
    HeroAngleRightImage as Hero,
    LogoCloudSimple as LogoCloud,
    FeatureAlternativeSideImage as Feature,
    FeatureAlternatingWithTestimonial as FeatureTestimonial,
    TestimonialImageOverlapping as Testimonial,
    LogoCloudSimpleBranded as LogoCloudBranded,
    StatsBackgroundImage as Stats,
    ContactSimpleTwoColumns as Contact
} from "@domenicosolazzo/site-designsystem"
import Layout from "../components/Layouts/Layout"
import { Error404, DataLinks} from "../data/index"
import { DataManagementPage as PageData } from "../data"

export default function ManagementPage() {
    const {
        hero,
        logoCloud,
        features,
        testimonial,
        featuresTestimonial,
        contact,
    } = PageData
    return(
        <Layout>
            <Hero {...hero} />
            <LogoCloud {...logoCloud} />
            <Feature {...features} />
            <Testimonial {...testimonial} />
            <FeatureTestimonial {...featuresTestimonial} />
            <LogoCloud {...logoCloud} />
            <Contact {...contact} />
        </Layout>
    )
}